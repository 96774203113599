/* site-wide styles */
@import '~font-awesome/css/font-awesome.css';
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0B406B;
  color: #fff;
  font-family: "Open Sans";
}
body {
  padding-top: 62px;
}
body.has-nav-bar {
  padding-top: 93px;
}
a{text-decoration:none !important}
  a:hover {
    text-decoration: underline !important
  }
.border-bottom-light {
  border-bottom: 1px solid #dee2e6;
}
.ql-picker-item[data-value="Arial"]::before,
.ql-picker-label[data-value="Arial"]::before {
  content: 'Arial' !important;
  font-family: "Arial" !important;
}
.ql-picker-item[data-value="Open Sans"]::before,
.ql-picker-label[data-value="Open Sans"]::before {
  content: 'Open Sans' !important;
  font-family: "Open Sans" !important;
}
.flex-table-row.cdk-drag-dragging {
  display: none;
}
.flex-table-row.cdk-drag-preview {
  background: #fff;
  opacity: 0.85;
}
.flex-table-row.cdk-drag-preview .cell {
  border-left: 1px solid #cfcfcf;
  padding: 10px;
}
.flex-table-row.cdk-drag-preview .cell a {
  color: #0B406B;
}
.flex-table-row.cdk-drag-preview .cell:first-child {
  border-left: none;
}
.flex-table .flex-table-row {
  margin-top: -1px;
}
.flex-table .cell {
  border-left: 1px solid #cfcfcf;
  padding: 10px;
}
.flex-table .cell a {
  color: #0B406B;
}
.flex-table .cell:first-child {
  border-left: none;
}
.flex-table .header {
  background: #0B406B;
  color: #fff;
}
.flex-table .header .cell {
  border-left: 1px solid #9eabb5;
}
.flex-table .header .cell:first-child {
  border-left: none;
}
.flex-table .body {
  background: #fff;
  color: #0B406B;
}
.flex-table.hover .body .flex-table-row:hover {
  background: #ebebeb;
}
.flex-table.striped .flex-table-row:nth-child(even) {
  background: #f3f3f3;
}
.card-image-stock {
  width: 100%;
  max-width: 310px;
  min-width: 250px;
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  margin: 15px;
}
.card-image-stock .deleted-image-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.55);
}
.card-image-stock.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 3px;
  color: #0B406B;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.card-image-stock.cdk-drag-placeholder {
  opacity: 0.75;
}
.card-image-stock.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.card-image-stock .inner-card {
  background-color: #fff;
  height: 100%;
  border-radius: 5px;
  text-align: center;
  position: relative;
}
.card-image-stock.active {
  background-color: #c6ffae;
}
.card-image-stock .card-img-top {
  padding: 5px;
  height: 200px;
  object-fit: contain;
  object-position: center;
  font-family: 'object-fit: contain; object-position: center;';
}
.card-image-stock a {
  color: #0B406B;
}
.btn {
  padding: 2px 10px;
  font-weight: bold;
  font-size: 14px;
}
.btn.btn-primary {
  background: #fff;
  border: 1px solid #0B406B;
  color: #0B406B;
  border-radius: 3px;
  margin: 0 5px;
}
.btn.btn-primary.active {
  background-color: #0B406B !important;
}
.btn.btn-primary:hover {
  background: #e8e4e4;
}
.btn.btn-outline-primary {
  color: #0B406B;
  border-color: #0B406B;
}
.btn.btn-outline-primary.active {
  background-color: #0B406B;
  color: #fff;
}
.btn.btn-outline-primary:hover {
  background: #e8e4e4;
}
.btn.btn-outline-warning {
  color: #0B406B;
}
.btn.btn-outline-warning.active {
  color: #0B406B;
}
.btn.btn-outline-warning:hover {
  color: #0B406B;
}
.modal-content {
  border-radius: 7px 7px 4px 4px !important;
}
.input-group-append .btn.btn-primary {
  border: 1px solid #fff;
  margin: 0;
}
.ngx-pagination .current {
  background: #0B406B !important;
}
.card .card-header {
  background: #0B406B;
  color: #fff;
  padding: 5px 15px;
}
.card .card-header h4 {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  line-height: 1.5;
}
.table thead {
  background: #0B406B;
  color: #fff;
}
.table.table-hover tbody a {
  color: #06243d;
  text-decoration: none;
}
.table.table-hover tbody tr:hover {
  background-color: #e6e6e6;
 
}
.table.table-hover tbody tr:hover a {
  text-decoration: underline;
  color: #01090f;
}
.form-control {
  background-color: transparent;
  color: #414141;
}
.form-control.max-width {
  max-width: 300px;
}
.form-control:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 transparent;
}
.border-bottom {
  border: none;
  border-bottom: 1px solid #0B406B !important;
  border-radius: 0;
}
#project-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
#designer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
/* Header Styles */
#header {
  height: 62px;
  padding: 15px;
  color: #fff;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0B406B;
  z-index: 1000;
}
#main-content {
  background-color: #F3F1E7;
  margin: 0 -15px;
  padding: 15px;
  color: #0B406B;
}
/* Footer Styles */
#footer {
  height: 35px;
  z-index: 1100;
  color: #fff;
  display: block;
  margin: 0 -15px;
}
#footer a {
  color: #E3CC36;
}
.fullscreen-modal .modal-dialog {
  width: calc(100% - 40px);
  height: calc(100% - 60px);
  max-width: none;
}
.fullscreen-modal .modal-dialog .modal-content {
  height: 100%;
}
.fullscreen-modal .modal-dialog .modal-content .modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fullscreen-modal .modal-dialog .modal-content .modal-body {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  bottom: 34px;
  overflow: auto;
}
.fullscreen-modal .modal-dialog .modal-content .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.search {
  max-width: 300px;
  left: 50%;
  margin-left: -150px;
  position: relative;
}
.search.input-group,
.search .input-group {
  height: 30px;
}
.search.input-group input,
.search .input-group input {
  background: #fff !important;
  max-width: 100% !important;
  height: 100%;
}
.cdk-drag-preview {
  z-index: 1080!important;
  color: #0B406B !important;
  opacity: 0.75 !important;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
